import { type RadarTrackingOptions } from "capacitor-radar";

import { type WorkerAvailabilityCalendarConfig } from "../WorkerAvailability/types";
import { type CbhFeatureFlag } from "./CbhFeatureFlag";

export enum EnableReferralSignupScreenVariants {
  OFF = "off",
  ON_WITH_CONTACTS = "on-with-contacts",
  ON_WITHOUT_CONTACTS = "on-without-contacts",
}

type MSABonusConfig = Record<string, Record<string, number>>;

interface DefaultBonusConfig {
  default: Record<string, number>;
  defaultReferralRate: number;
}

export type SignupBasedReferralsBonusConfig = DefaultBonusConfig | MSABonusConfig;

type HcpLicenseFieldsRequirementsVariant = Record<
  string,
  Record<string, "hidden" | "visible" | "allowed">
>;

type HcpLicenseDefaultStatusVariant = Record<
  string,
  {
    status: "Active" | "Pending";
  }
>;

interface NlcStatesVariant {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  active_states: string[];
}

interface PersonalIDSubtypesVariant {
  manual: string[];
  stripe: string[];
}

export interface RadarSdkConfigOnSiteTrackingVariant {
  ios: RadarTrackingOptions;
  android: RadarTrackingOptions;
}

interface RateNegotiationActivationVariant {
  id: string[];
  state: string[];
  msa: string[];
  global: boolean;
}

interface RateNegotiationLimitsVariant {
  [k: string]: number;
  Maximum: number;
  Minimum: number;
}

interface ToastOptions {
  header: string;
  message: string;
}

interface SentHomeRequestConfigVariant {
  failureToast: ToastOptions;
  successToast: ToastOptions;
  confirmationAlert: ToastOptions;
  isSentHomeFlowV2Enabled: boolean;
}

export interface SupportLinksVariant {
  INTRO_BILL_TERMS?: string;
  DNR_REQUESTS_WORKER?: string;
  ACCOUNT_SUSP_DEACT?: string;
  CHARGE_RATES_GUIDE?: string;
  DNR_REQUESTS_FACILITY?: string;
  INV_ACCURACY?: string;
  CHARGE_RATE_GUIDE?: string;
  TIMESHEET_SIGNATURES?: string;
  WORK_WITH_FRIENDS?: string;
  HELP_CENTER_WORKER?: string;
  BILLING_FAQ?: string;
  RATE_NEGOTIATION_ADMIN?: string;
  WHY_SSN?: string;
  RATE_NEGOTIATION_WORKPLACE?: string;
  OLD_BILLING_FAQ?: string;
  RATE_NEGOTIATION_WORKER?: string;
  BACKGROUND_CHECKS?: string;
  ATTENDANCE_SCORE?: string;
  REFERRAL_BONUS?: string;
  ACCOUNT_STATUS?: string;
  TIME_NEGOTIATION_WORKER?: string;
  HOW_TO_CONTACT_SUPPORT?: string;
  SIGN_IC_AGREEMENT?: string;
}

interface WorkWithFriendsBonusesVariant {
  addFriendBonus?: number;
  groupAttendanceBonus?: number;
}

interface OpenShiftCardAgentRequestVariant {
  agentReq?: Record<string, boolean>;
  global: true;
}

interface SendFCFToUrgentShiftsFlowVariant {
  timeWindow: number;
  enabled: boolean;
}

export interface MaxReferralBonusConfig {
  onboardingBonusInDollars?: number;
  postOnboardingBonusInDollars?: number;
}

export enum WorkerShiftsRedesignRolloutStage {
  OFF = "OFF",
  WORKER_SHIFTS = "MY_SHIFTS",
  WORKER_SHIFTS_CLOCK_IN = "CLOCK_IN",
  WORKER_SHIFTS_TIMEKEEPING = "SHIFT_TIMEKEEPING",
}

export interface FeatureFlagVariants {
  [CbhFeatureFlag.FACILITY_TYPE_SHIFT_ICONS]: Record<string, boolean>;
  [CbhFeatureFlag.HCP_APP_RATING_PROMPT_INTERVAL]: number;
  [CbhFeatureFlag.HCP_LICENSE_FIELDS_REQUIREMENTS]: HcpLicenseFieldsRequirementsVariant;
  [CbhFeatureFlag.HCP_LICENSE_DEFAULT_STATUS]: HcpLicenseDefaultStatusVariant;
  [CbhFeatureFlag.NLC_STATES]: NlcStatesVariant;
  [CbhFeatureFlag.ONBOARDING_STRIPE_SETUP_UNSKIPPABLE]: boolean;
  [CbhFeatureFlag.PERSONAL_ID_SUBTYPES]: PersonalIDSubtypesVariant;
  [CbhFeatureFlag.RADAR_SDK_CONFIG_ONSITE_TRACKING]: RadarSdkConfigOnSiteTrackingVariant;
  [CbhFeatureFlag.RATE_NEGOTIATION_ACTIVATION]: RateNegotiationActivationVariant;
  [CbhFeatureFlag.RATE_NEGOTIATION_LEAD_TIME]: number;
  [CbhFeatureFlag.RATE_NEGOTIATION_LIMITS]: RateNegotiationLimitsVariant;
  [CbhFeatureFlag.READS_FILE_STORAGE_MOBILE]: boolean;
  [CbhFeatureFlag.SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION]: boolean;
  [CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG]: SentHomeRequestConfigVariant;
  [CbhFeatureFlag.SHIFTS_SORT_KEY_TIMESLOT]: "pay" | "distance";
  [CbhFeatureFlag.SHOW_CANCELLATION_PAYOUT]: boolean;
  [CbhFeatureFlag.SUPPORT_LINKS]: SupportLinksVariant;
  [CbhFeatureFlag.TIMESHEETS_V2_NON_IP]: boolean;
  [CbhFeatureFlag.URGENT_SHIFTS]: boolean;
  [CbhFeatureFlag.USE_ORIGINALAMOUNT_FIELD_NON_IP]: boolean;
  [CbhFeatureFlag.WORK_WITH_FRIENDS_BONUSES]: WorkWithFriendsBonusesVariant;
  [CbhFeatureFlag.OPEN_SHIFT_CARD_AGENT_REQ]: OpenShiftCardAgentRequestVariant;
  [CbhFeatureFlag.SEND_FCF_TO_URGENT_SHIFTS_FLOW]: SendFCFToUrgentShiftsFlowVariant;
  [CbhFeatureFlag.SIGNUP_BASED_REFERRAL]: SignupBasedReferralsBonusConfig;
  [CbhFeatureFlag.ENABLE_REFERRAL_SIGNUP_SCREEN]: EnableReferralSignupScreenVariants;
  [CbhFeatureFlag.ENABLE_BROWSING_SHIFTS_ACROSS_US_MAP_VIEW]: boolean;
  [CbhFeatureFlag.FACILITY_DISTANCE_THRESHOLD_MAP_VIEW_CONFIG]: Record<string, number>;
  [CbhFeatureFlag.SIGN_UP_REFERRAL_SCREEN_EXPIRATION_DAY]: number;
  [CbhFeatureFlag.SHOW_ONBOARDING_AGENT_PROFILE_ERROR_MODAL]: boolean;
  [CbhFeatureFlag.MAX_REFERRAL_BONUS_CONFIG]: MaxReferralBonusConfig;
  [CbhFeatureFlag.HCP_CAN_EDIT_DATE_FOR_MULTI_DAY_SHIFTS]: boolean;
  [CbhFeatureFlag.SHOW_SHIFT_STATUS_FOOTER_INFO]: boolean;
  [CbhFeatureFlag.URGENT_SHIFTS_CONFIG]: {
    /**
     * @deprecated FIXME: Should use services URL environment not from LD.
     * All it's usages must read from env.
     */
    urgentShiftsServiceUrl: string;
    commuteUrgentShiftBooking?: { subTitle?: string; bodyText?: string };
    lateUrgentShiftBooking?: { subTitle?: string; bodyText?: string };
    checkAttendanceBoundsInMinutes?: number[];
    urgentShiftPageQueryErrorMessage?: string;
    infoItems: string[];
    noLocationBookingAlertMessage: { android: string; ios: string };
  };
  [CbhFeatureFlag.ENABLE_REFERRAL_SUBMISSION_ONBOARDING_SCREEN]: boolean;
  [CbhFeatureFlag.ENABLE_WORKER_AVAILABILITY]: {
    qualifications: string[];
  };
  [CbhFeatureFlag.WORKER_AVAILABILITY_CALENDER_CONFIG]: WorkerAvailabilityCalendarConfig;
  [CbhFeatureFlag.URGENT_SHIFTS_HOURLY_PAY_RATE]: boolean;
  [CbhFeatureFlag.RADAR_SDK_CONFIG]: {
    android: Record<string, unknown>;
    ios: Record<string, unknown>;
  };
  [CbhFeatureFlag.HCP_LICENSE_NUMBER_REQUIREMENTS]: Record<string, string[]>;
  [CbhFeatureFlag.WORKPLACE_REFERRALS]: {
    workerToWorkplace?: boolean;
    workerToWorkplaceIncentive?: Record<string, number>;
  };
  [CbhFeatureFlag.ENABLED_REFERRAL_PROGRAMS]: {
    activationReferralIsEnabled: boolean;
    signupReferralIsEnabled: boolean;
  };
  [CbhFeatureFlag.WORKER_TO_WORKPLACE_AFFILIATE_EXPERIMENT]: boolean;
  [CbhFeatureFlag.WORKER_TO_WORKER_REFERRAL_ENABLED_BANNERS]: {
    enterReferralCodeBannerIsVisible: boolean;
    referAndEarnBannerIsVisible: boolean;
  };
  [CbhFeatureFlag.UNVERIFIED_SHIFTS_V2_ENABLED]: boolean;
  [CbhFeatureFlag.HOME_HEALTH_ENABLED]: boolean;
  [CbhFeatureFlag.HOME_HEALTH_BOOK_DIALOG_ATTENDANCE_ENABLED]: boolean;
  [CbhFeatureFlag.HOME_HEALTH_VISIT_REQUIREMENT_CHECK]: boolean;
  [CbhFeatureFlag.RELIABILITY_RANKING_EXPERIMENT]: boolean;
  [CbhFeatureFlag.WORK_WITH_FRIENDS_V2]: boolean;
  [CbhFeatureFlag.MISSED_SHIFTS_CONFIGURATION]: {
    enabled: boolean;
    maxResultsToShow?: number | undefined;
    minimumPayPercentile?: number | undefined;
  };
}
